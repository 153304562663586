.App-slider-wrapper{
    height: 60px;
    position: relative;
    .App-progress{
        height: 16px;
        position: absolute;
        left: 0;
        right: 0;
        top: 26px;
    }
    .App-slider-disable-mask{
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index: 10;
    }
}
.App-slider {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 20px;
    z-index: 5;
    span{
        display: block;
        text-align: center;
        color: rgba(217, 177, 84, 1);
        font-weight: 700;
    }
    input{
        -webkit-appearance: none;
        overflow:hidden;
        width: 100%;
        height: 34px;
        background: transparent;
        outline: none;
    }
    input::-webkit-slider-thumb{
        -webkit-appearance: none;
        position: relative;
        width: 32px;
        height: 32px;
        background: url(./assets/btn.png);
        background-size: 100% 100%;
        border-radius: 50%;
        transition: .2s;
    }
}
.App-slider-value{
    color: rgba(217, 177, 84, 1);
    font-weight: 700;
    font-size: 14px;
}
.App-slider-minis,
.App-slider-plus{
    width: 48px;
    height: 48px;
    background: url(./assets/btn.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    z-index: 8;
    cursor: pointer;
    
    &::before{
        content: ' ';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 14px;
        top: 14px;
    }
}
.App-slider-minis{
    left:0;
    &::before{
        background: url(./assets/minis.png) no-repeat 0 50%;
        background-size: 100% auto;
    }
}
.App-slider-plus{
    right: 0;
    &::before{
        background: url(./assets/plus.png) no-repeat 0 50%;
        background-size: 100% auto;
    }
}

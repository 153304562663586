.App-form-input{
	height: 34px;
	background: rgba(12,9,4,0);
	border: 1px solid #573C2F;
	outline: none;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8000);
	border-radius: 10px;
	font-size: 14px;
	font-weight: 400;
	color: #705A47;
	line-height: 50px;
	text-indent: 5px;
}
.App-btn-search{
	background: url(./assets/btn.png);
	background-size: 100% 100%;
	display: inline-block;
	width:36px;
	height:36px;
	font-size: 0;
	position: relative;
}
.App-btn-search::after{
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	content: " ";
	width: 100%;
	height: 100%;
	background: url(./form/search.png) no-repeat center 55%;
	background-size: 50% 60%;
	cursor: pointer;
}

.App-select-panal{
	padding: 5px 10px;
	text-align: center;
	position: relative;
}
.App-select-panal::before{
	content: ' ';
	display: block;
	position: absolute;
	left:0;
	top: 3px;
	width: 3px;
	height:580px;
	background:url(./icons/line.png) no-repeat 0 0;
	background-size: 100% 100%;
	transform: rotate(270deg);
	transform-origin: 0 0;
}

.ReactModal__Overlay, 
.ReactModal__Overlay--after-open {
    z-index: 199;
}
.ReactModal__Content{
    z-index: 200;
}

.App-game-modal {
	outline: none;
	background: url(./modal/dia-bg.png) repeat-x 0 0;
	background-size: auto 100%;
	width: 1000px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -500px;
}
.App-game-modal::before,
.App-game-modal::after{
	content: ' ';
	display: block;
	position: absolute;
	top: 0; bottom: 0;
	height: 100%;
	width: 30px;
	background-image: url(./modal/dia-l.png) ;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: auto 100%;
}
.App-game-modal::before{
	left: -30px;
}
.App-game-modal::after{
	right: -30px;
	transform: rotateY(180deg);
}

/*App-game-modal-transparent*/
.App-game-modal-transparent {
	outline: none;
	background: transparent;
	width:100%;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 0;
	margin: -200px 0 0 0;
}

/*gray*/
.App-game-modal-gray {
	outline: none;
	background: url(./modal/dia2.png) no-repeat 0 0;
	background-size: auto 100%;
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	padding-left: 30px;
	color: #fff8ca;
}
.App-game-modal-gray::before{
	content: ' ';
	display: block;
	position: absolute;
	right: -30px; bottom: 0;
	height: 100%;
	width: 400px;
	background-image: url(./modal/dia2.png) ;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: auto 100%;
	transform: rotateY(180deg);
}

.App-game-modal-noborder{
	background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(36, 21, 4, 0.1), #060300, rgba(36, 21, 4, 0.1), rgba(0, 0, 0, 0));
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	outline: none;
}
/*gray headless*/
.App-game-modal-gray-headless {
	outline: none;
	background: url(./modal/dia-headless.png) no-repeat 0 0;
	background-size: auto 100%;
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	padding-left: 30px;
	color: #fff8ca;
}
.App-game-modal-gray-headless::before{
	content: ' ';
	display: block;
	position: absolute;
	right: -30px; bottom: 0;
	height: 100%;
	width: 400px;
	background-image: url(./modal/dia-headless.png) ;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: auto 100%;
	transform: rotateY(180deg);
}
.App-game-modal-chat::before {
	width: 40px;
}

.App-game-modal-chain-headless {
	outline: none;
	background: url(./modal/dia-chain-01.png) no-repeat 0 0,
		url(./modal/dia-chain-03.png) no-repeat 0 bottom;
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	padding-left: 30px;
	color: #fff8ca;
}
.App-game-modal-chain-headless::after{
	content: ' ';
	display: block;
	background: url(./modal/dia-chain-02.png) repeat-y 0 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 69px;
	bottom: 69px;
	z-index: -1;
}
.App-game-modal-gift{
	outline: none;
	background: url(./modal/border.png) no-repeat 0 0;
	background-size: 100% 100%;
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	padding-left: 30px;
	color: #fff8ca;
}

.App-game-modal-title{
	background: url(./modal/dia-title.png) no-repeat 100% 0;
	background-size: auto 100%;
	height:2rem;
	line-height: 2rem;
	padding-right: 3rem;
	padding-left: 1rem;
}
.App-game-modal-header {
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 3rem;
}
.App-game-modal-content{
	background: #1e160c;
	font-size: 14px;
	border-radius: 20px;
	padding: 20px;
	margin: 20px 5px;
}

.App-modal-tips {
    background-image: url(./modal/tip.png);
    background-size:100% auto;
    color: #ffffc2;
    position: absolute;
    top:70px;
    left:50%;
    width: 25rem;
    height:20px;
    margin-left: -12.5rem;
    // background: linear-gradient(90deg, rgba(0,0,0,0), rgba(36,21,4,0.1), #060300, rgba(36,21,4,0.1), rgba(0,0,0,0));
    z-index: 1000;
    text-align: center;
    padding:10px;
}
.App-game-modal-toast{
	background: url(./modal/toast-ok.png) repeat-x 0 -61px;
	background-size: auto 306%;
	width: 400px;
	height: 60px;
	position: fixed;
	color: #fff8ca;
	line-height: 60px;
	text-align: center;
	outline: none;
}
.App-game-modal-toast::before,
.App-game-modal-toast::after{
	content: ' ';
	display: block;
	height: 60px;
	width: 60px;
	position: absolute;
	top:0;
}
.App-game-modal-toast::before{
	background: url(./modal/toast-ok.png) no-repeat 0 0;
	background-size: 100% auto;
	left:-60px;
}
.App-game-modal-toast::after{
	background: url(./modal/toast-ok.png) no-repeat 0 98.9%;
	background-size: 100% auto;
	right:-60px;
}
.App-game-modal-toast-fail{
	background: url(./modal/toast-fail.png) repeat-x 0 -61px;
	background-size: auto 306%;
}
.App-game-modal-toast-fail::before{
	background: url(./modal/toast-fail.png) no-repeat 0 0;
	background-size: 100% auto;
}
.App-game-modal-toast-fail::after{
	background: url(./modal/toast-fail.png) no-repeat 0 98.9%;
	background-size: 100% auto;
}
.App-loading-content{
	color: rgba(231, 214, 178, 1);
	font-size: 100%;
	text-align: center;

	img{
		margin-bottom: 20px;
	}
}


.App-da-modal{
	outline: none;
	background: url(./modal/da-border.png) no-repeat 0 0;
	background-size: 100% 100%;
	width: 800px;
	height: 400px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -200px 0 0  -400px;
	padding-left: 30px;
	color: #fff8ca;
}

@media screen and (orientation: portrait) {
   .App-mobile #root{
        overflow: hidden;
        -webkit-transform:rotate(-270deg);
        -moz-transform: rotate(-270deg);
        -ms-transform: rotate(-270deg);
        transform: rotate(-270deg);
        // transform-origin: 26%;
    }
    .App-mobile .ReactModal__Content{
        -webkit-transform:rotate(-270deg);
        -moz-transform: rotate(-270deg);
        -ms-transform: rotate(-270deg);
        transform: rotate(-270deg);
        transform-origin: 95.5%;
    }
    .App-mobile #root #App-phaser-parent{ 
        -webkit-transform:rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        // transform-origin: 50% 26%;

	    canvas{
	    	position: fixed;
	    	right: 0;
	    	z-index: 1;
	    }
    }
}

@media screen and (orientation: landscape) {
    .App-mobile #root {
        -webkit-transform:rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        transform-origin: 0;
    }
    .App-mobile .ReactModal__Content{
        -webkit-transform:rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }
}  

.App-mobile {
	overflow: hidden;

	.App-seaon-map-canvas-rotate canvas{
	}

	.ReactModal__Overlay{
		background: rgba(0,0,0,0.3) !important;
	}
	.ReactModal__Content{
		zoom: 0.85;
		top: 50% !important;
		left: -50% !important;
	}

	.App-game-modal-mantance{
		zoom: 0.7;
		margin-top: 50px !important;
		margin-left: 90px !important;
	}

	.App-season-menus{
		zoom:0.85;
	}
	.App-reward-claim-area{
		zoom: 0.80;
		margin-right: 5px;
	}
	.App-loading-header{
		padding: 5px;
		text-align: left;
	}
	.App-loading-header img{
		zoom: 0.2;
	}

	.App-season-list{
		width: 70%;
		min-height: 100%;
		text-align: center;
	}
	.App-loaded, .App-loading{
		min-width: 100%;
		min-height: 100%;
	}
	.App-gallery-prev,
	.App-gallery-next {
		top: 100px;
	}
	.App-season-item{
		zoom: 0.9;
		margin-top: 5px;
	}
	.App-season-filter{
		text-align: left;
		zoom: 0.8;
		left: -80px;
		top: -45px;
	}
	.App-season-menus{
		justify-content: flex-end;
	}
	.App-season-menus-left{
		margin-right: 20px;
	}

	.App-userInfo,
	.App-topbar-middle,
	.App-topbar-right{
		zoom: 0.7;
	}
	.App-userInfo .name{
		width: 7rem;
	}
	.App-topbar-right{
		background-size: 100% 100%;
		width: 150px;
	}
	.App-userInfo{
		margin-top: -5px;
	}
	.App-topbar-middle{
		background-size: 100% 100%;
		width: 420px;
		left: 60%;
		.App-mertrial-item{
			min-width: 4rem;
			margin:0 2px;
		}
	}

	.App-general-list{
		zoom: 0.8;
	}
	.App-preview-map-btn,
	.App-morale-map-btn,
	.App-union-bonus-btn,
	.App-offers-btn,
	.App-chat-map-btn,
	.App-loading-btn,
	.App-help-guidebook a{
		zoom: 0.7;
		top: 100px;
	}
	.App-map-custome-btn{
		zoom: 0.7;
	}
	.App-game-modal-avatar-select,
	.App-game-modal-avatar-point,
	.App-game-modal-restat{
		zoom: 0.8;
		margin-top: 80px !important;
		margin-left: -90px !important;
	}

	.App-toolbar{
		zoom: 0.7;
	    width: 560px;
    	padding: 0 10px;
    	margin-left: -320px;
	}
	.App-toolbar-item{
		width: 60px;
	}
	.App-game-modal-reward,
	.App-game-modal-gpt{
		zoom: 0.7;
		width: 800px !important;
		height: 460px !important;
		margin-top: 134px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-gpt-avatar{
		zoom: 0.7;
		height: 460px !important;
		margin-top: -80px !important;
		margin-left: 160px !important;
	}
	.App-game-modal-signsteps{
		zoom: 0.7;
		margin-top: 180px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-vote{
		zoom: 0.7;
		width: 800px !important;
		margin-top: 120px !important;
		margin-left: -240px !important;
		.App-game-map-view-main{
			width: 500px !important;
		}
	}
	.App-game-modal-sign-overview{
		zoom: 0.7;
		margin-top: 120px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-mappreview{
		zoom: 0.7;
		margin-top: 120px !important;
		margin-left: -280px !important;
		width: 840px !important;
		#App-phaser-preview,
		.App-game-preview-rule{
			width: 520px !important;
		}
		.App-game-preview-rule+.App-game-table-rank{
			margin-left: 0 !important;
		}
	}
	.App-game-modal-charge{
		zoom: 0.7;
		margin-top: 160px !important;
		margin-left: -280px !important;
	}
	.App-game-modal-morale{
		zoom: 0.7;
		margin-top: 130px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-bonus{
		zoom: 0.7;
		margin-top: 130px !important;
		margin-left: -200px !important;
	}
	.App-game-modal-chat{
		margin-top: 80px !important;
		margin-left: -40px !important;
		height: 300px !important;
	}
	.App-game-modal-chat-sendblock{
		margin-top: 100px !important;
		margin-left: 40px !important;
		height: 200px !important;
	}
	.App-game-modal-chat{
		.App-game-chat-main{
			max-height: 140px;
			overflow: auto;
		}
		.App-loading-btn{
			padding-top: 5px;
			zoom: 1 !important;
		}
	}
	.App-game-modal-gift{
		zoom: 0.7;
		height: 480px !important;
		width: 780px !important;
		margin-top: 120px !important;
		margin-left: -210px !important;
		.App-offer-content-title{
			font-size: 1.4rem !important;
			padding: 20px 0 0;
		}
		.App-offer-upgrade-item img {
			zoom: 0.7;
		}
		.App-offer-reward{
			padding: 10px;
		}
	}
	.App-game-modal-playerpop{
		zoom: 0.8;
		width: 700px !important;
		height: 340px  !important;
        margin-left: -220px !important;
        margin-top: 140px !important;

        .App-game-modal-playerpop-part+.App-game-modal-playerpop-part{
        	margin-left: 30px;
        	padding-left: 30px;
        	background: url(./assets/line.png) no-repeat 0 0;
        	background-size: auto 120%;
        }
        .App-user-status-info{
        	background: none;
        }
        .App-user-status-basic {
        	margin-top: 30px;
        	margin-bottom: -20px;
        }
        .App-user-status-basic h2{
        	line-height: 1;
        }
        .chain-content-wallet{
        	width: 10rem;
        	margin: 0 auto;
        }
        .gpt-title{
        	display: none;
        }
        .App-user-status-buff{
        	margin-top: 20px;
        }
	}
	.App-user-status-basic h2{
		font-size: 14px;
	}
	.App-user-status-basic img,
	.App-general-item-avatar{
	}
	.App-game-modal-playerpop-part{
		display: inline-block;
		width: 45%;
	}

	.App-game-modal-general-select{
		zoom: 0.7;
		width: 840px !important;
		height: 440px !important;
		margin-top: 145px !important;
		margin-left: -280px !important;

		.App-general-detail{
			padding-top: 0 !important;
			.App-general-data{
				margin-bottom: 5px !important;
			}
		}
		.App-general-teams{
			padding-top: 30px;
		}
		.App-general-current{
			margin-top: -40px !important;
		}
		.App-game-modal-general-select-btn{
			bottom: 80px !important;
		}
		.App-general-logis{
			.App-skill-item{
				margin-bottom: 5px; 
			}
			.App-skill-item .level img{
				zoom: 0.8;
			}
			.App-skill-item .buff h2{
				line-height: 1.2;
			}
		}
	}
	.App-game-modal-general-manage{
		zoom: 0.7;
		width: 840px !important;
		margin-top: 155px !important;
		margin-left: -250px !important;
		.App-general-teams{
			width: 300px;
		}
		.App-general-current{
			width: 140px;
		}
		.App-general-current .big-body img {
			width: 200px;
			margin: -20px 0 0 -30px;
		}
		.App-general-detail-main img.type{
			margin-left: 20px;
		}
		.App-general-logis{
			width: 300px;
			.App-stats-list{
				width: 340px;
			}
			.App-stats-item{
				width: 46%;
				vertical-align: top;
			}
			.App-skill-item{
				width: 320px;
				.buff{
					width: 180px;
				}
			}
		}
	}

	.App-modal-beginner{
		zoom: 0.3;
		margin-top: 40px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-buildings{
		zoom: 0.7;
		margin-top: 130px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-strategy{
		zoom: 0.7;
		margin-top: 160px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-battle {
		zoom: 0.7;
		margin-top: 200px !important;
		margin-left: -320px !important;
	}
	.App-game-modal-troops {
		zoom: 0.7;
		width: 840px !important;
		margin-top: 150px !important;
		margin-left: -240px !important;
	}
	.App-game-modal-confirm,
	.App-game-modal-gray-disconnect,
	.App-game-modal-tips{
		zoom: 0.7;
		margin-top: 100px !important;
		margin-left: 160px !important;
	}
	.App-game-modal-confirm{
		margin-top: 160px !important;
		margin-left: 0 !important;
	}
	.App-game-modal-toast{
		zoom: 0.7;
		margin-top: 160px !important;
		margin-left: 160px !important;
	}
	.App-game-modal-willwon{
		margin-top: -140px !important;
		margin-left: 20px !important;
	}
	.App-game-modal-activity{
		zoom: 0.7;
		width: 840px !important;
		margin-top: 210px !important;
		margin-left: -245px !important;
		.App-activitiy-list{
			width: 330px;
			overflow: auto;	
		}
	}
	.App-game-modal-season-rule{
		zoom: 0.7;
		margin-top: 105px !important;
		margin-left: -220px !important;
		width: 780px !important;
    	height: 500px !important;
    	.App-game-season-rule-wrapper{
    		padding: 15px 10px;
    		width: 760px;
    	}
    	.App-game-season-rule{
    		width: 48%;
    	}
    	.App-game-season-rank{
    		width: 52%;
    	}
    	.App-game-season-data label, .App-game-season-data span{
    		display: inline-block;
    	}
    	.App-game-season-data label{
    		width: auto;
    	}
    	.App-game-season-data span{
    		width: 1rem;
    		margin-right: 1rem;
    	}
	}
	.App-world-records-fold{
		top: 350px;
	}
	.App-world-records-notify-wrapper{
		zoom: 0.7;
	}
	.App-game-modal-events{
		zoom: 0.7;
		height: 300px !important;
		margin-top: 365px !important;
		margin-left: 100px !important;
	}
	.App-world-records-list{
		height: 170px;
		overflow: auto;
	}
	.App-game-modal-share{
		margin-top: 83px !important;
		margin-left: -90px !important;
	}
	.App-game-modal-loading{
		margin-top: 100px !important;
		margin-left: 80px !important;
	}

	.App-game-modal-spy{
		zoom: 0.7;
		margin-top: 130px !important;
		margin-left: -60px !important;
	}

	.App-game-modal-action-esult{
		margin-top: 100px !important;
		margin-left: 40px !important;
	}

	.App-game-modal-block{
		zoom: 0.7;
		height: 480px !important;
		margin-top: 130px !important;
		margin-left: -240px !important;
		
		.App-game-modal-block-content{
		    padding-top: 45px;
		}
		.App-game-modal-header{
	        margin: -110px 0 50px;
	        width: 800px;
	    }
		.App-block-types{
			padding-top: 20px !important;
		}
		.App-block-panel-cod{
			width: 760px;
			position: relative;
			.App-cod-header{
				margin: 10px 0 0;
				.App-general-cod-lastTime{
					order: 1000;
				}
				.App-general-person{
				}
				.App-general-data{
					display: flex;
					margin-right: 50px;
					.App-general-logis-item{
						display: block;
						background: none;
						padding-left: 20px;
						height: 80px;
						font-size: 16px;
						.App-item-icon{
							position: absolute;
						}
						.stats{
							display: block;
							width: 100%;
							padding:0 0 0 25px;
						}
						.current {
							text-align: left;
							line-height: 1;
							margin-left: -5px;
						}
					}
				}
			}
			.App-cod-msg-troops{
				margin-top: -20px;
			}
			.App-block-cod-members{
				height: 210px;
				width: 780px;
				padding-top: 0;
				position: absolute;
				top: 105px;
				left: 0;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
			}
			.App-block-cod-member{
				width: 48%;
				.App-block-cod-troops{
					margin: 0 0.2rem;
				}
			}
			.App-block-cod-member:nth-child(even){
				margin-left: 10px;
			}
		}
	}

	.App-battle-over-lost{
		width: 200%;
	}

	.App-game-modal-map-custom{
		zoom: 0.65;
		margin-top: 10px !important;
		margin-left: -100px !important;
	}

	.App-game-modal-wallet-select{
		zoom: 0.65;
		margin-top: 10px !important;
		margin-left: 110px !important;
	}
	.App-game-modal-wallet-select2{
		zoom: 0.65;
		margin-top: 140px !important;
		margin-left: -180px !important;
	}
	.App-wallets-item-binanceQrcode,
	.App-wallets-item-walletConnect{
		display: none;
	}
	.App-wallets-item-binance,
	.App-wallets-item-trustwallet{
	    display: inline-block;
	}


	/*pvp	*/
	.App-pvp-modal-signsteps{
		margin-top: 140px !important;
		margin-left: -80px !important;
	}
}
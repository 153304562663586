//redponit
.App-redpoint{
    font-size: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
    border: 7px solid #f57016;
    box-shadow: 2px 2px 2px rgba(245,111,22,0.5), 
        2px -2px 2px rgba(245,111,22,0.5), 
        -2px 2px 2px rgba(245,111,22,0.5), 
        -2px -2px 2px rgba(245,111,22,0.5);
    position: absolute;
    margin: -2px -2px 0;
    cursor: pointer;
}
.App-redpoint::before{
    display: block;
    content: ' ';
    width: 10px;
    height: 10px;
    background: #cf0001;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    cursor: pointer;
}

// btn
.App-btn-disable{
    filter: grayscale(100%);
    filter: gray;
}

/*icons*/
.App-item-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    vertical-align: middle;
    cursor: pointer;
}
.App-item-icon-training {
    background: url(./icons/training.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-troop {
    background: url(./icons/troop.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-activities {
    background: url(./icons/activities.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-silver {
    background: url(./icons/silver.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-check {
    background: url(./icons/check.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-greenArr {
    background: url(./icons/green-arr.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-redArr {
    background: url(./icons/red-arr.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-diamond {
    background: url(./icons/diamond.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-reward {
    background: rgba(100,100,100,0.4) url(./icons/reward.png) 0 0;
    background-size: 100% 100%;
    border-radius: 5px;
}
.App-item-icon-hot {
    background: url(./icons/hot.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-selected {
    background: url(./icons/selected.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-morale {
    background: url(./icons/morale.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-lighting {
    background: url(./icons/lighting.png) no-repeat 0 0;
    background-size: auto 100%;
}

.App-item-icon-coin {
    background: url(./icons/icon-coins.png) 0 0;
    background-size: 100% 100%;
}

.App-item-icon-back {
    background: url(./btn/btn-back.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-shares {
    background: url(./icons/protect.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-protect {
    background: url(./icons/protect2.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-yuzu {
    background: url(./icons/yuzu.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-bnb {
    background: url(./icons/bnb.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-heal {
    background: url(./icons/heal.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-share {
    background: url(./icons/share.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-crossChain {
    background: url(./icons/crossChain.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-emerald {
    background: url(./icons/reward.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-lot {
    background: url(./icons/lot.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-bsc,
.App-item-icon-bsctest {
    background: url(./icons/bnb.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-aZETA,
.App-item-icon-zeta,
.App-item-icon-zetatest {
    background: url(./icons/zetatest.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-ZETA,
.App-item-icon-zeta {
    background: url(./icons/zeta.png) 0 0;
    background-size: 100% 100%;
}
.App-item-icon-stakedzeta {
    background: url(./icons/stakedzeta.png) 0 0;
    background-size: 100% 100%;
    border-radius: 50%;
}
.App-item-icon-stzeta {
    background: url(./icons/stzeta.png) 0 0;
    background-size: 100% 100%;
    border-radius: 50%;
}
.App-item-icon-usdt,
.App-item-icon-ustd {
    background: url(./icons/ustd.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-mumbaitest {
    background: url(./icons/mumbaitest.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-goerlitest {
    background: url(./icons/goerlitest.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-usd {
    background: url(./icons/usd.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-general-buff {
    background: url(./icons/general-buff.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-quit {
    background: url(./icons/quit.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-plot {
    background: url(./icons/pos.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-score {
    background: url(./icons/score.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-star {
    background: url(./icons/star-da.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-vote {
    background: url(./icons/vote.png) 0 0px;
    background-size: 100% 100%;
}
.App-item-icon-number{
    border: 1px solid yellow;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    zoom: 0.7;
}
.App-item-icon-stars{
    letter-spacing: 8px;
}
.App-item-icon-stars.App-item-icon-small{
    font-size: 12px;
    letter-spacing: 0;
    zoom: 0.9;
}

// info
.App-item-icon-info-tips{
    background-image: url(./icons/icon-info.png);
    background-size: auto 100%;
}

/*gemneral pop*/
.App-item-icon-general,
.App-item-icon-sprite{
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size:auto 100%;
    margin-top: -20px;
}
.App-item-icon-general{
    background-image: url(./icons/general.png);
}
.App-item-icon-sprite{
    background-image: url(./icons/sprite.png);
}

/*logis*/
.App-item-icon .product,
.App-item-icon .hire,
.App-item-icon .load,
.App-item-icon .attack,
.App-item-icon .defense{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image:url(./icons/logis.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-left: -10px;
}
.App-item-icon .attack{
    background-position: -1px -2px;
}
.App-item-icon .defense{
    background-position: -1px -33px;
}
.App-item-icon .load{
    background-position: -1px -65px;
}
.App-item-icon .product{
    background-image:url(./icons/fist.png);
}
.App-item-icon .hire{
    background-position: -1px -130px;
}


// 地块
.App-item-icon-tile-status{
    background:url(./icons/tile-status.png) no-repeat 0 0;
    background-size: 100% 100%;
}
.App-item-icon-tile-buff{
    background:url(./icons/tile-buff.png) no-repeat 0 0;
    background-size: 100% 100%;
}
.App-item-icon-tile-coins{
    background:url(./icons/tile-coins.png) no-repeat 0 0;
    background-size: 100% 100%;
}
.App-item-icon-tile-roseSpeed{
    background:url(./icons/tile-roseSpeed.png) no-repeat 0 0;
    background-size: 100% 100%;
}
.App-item-icon-tile-lasting{
    background:url(./icons/tile-lasting.png) no-repeat 0 0;
    background-size: 100% 100%;
    width:47px;
    height:58px;
}
.App-item-icon-blocks{
    background:url(./icons/blocks.png) no-repeat 0 0;
    background-size: 100% 100%;
}
.App-item-icon-camp{
    background:url(./icons/camp.png) no-repeat 0 0;
    background-size: 100% 100%;
}

/*bottom*/

.App-item-icon-season,
.App-item-icon-building,
.App-item-icon-hero,
.App-item-icon-strategy,
.App-item-icon-battle,
.App-item-icon-team,
.App-item-icon-recruit{
    background-repeat: no-repeat;
    background-size:100% auto;
    background-position: 0 bottom;
    width: 80px;
    height: 90px;
}
.App-item-icon-season{
    background-image:url(./btn/b1.png);
}
.App-item-icon-building{
    background-image:url(./btn/b2.png);
}
.App-item-icon-hero{
    background-image:url(./btn/b3.png);
}
.App-item-icon-strategy{
    background-image:url(./btn/b4.png);
}
.App-item-icon-battle{
    background-image:url(./btn/b5.png);
}
.App-item-icon-team{
    background-image:url(./btn/b6.png);
}
.App-item-icon-recruit{
    background-image:url(./btn/b7.png);
}

/*btn*/
.App-general-btn{
    display: inline-block;
    height: 20px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    color:#fff;
    white-space: nowrap;
    .heart{
        color:red;
        font-size: 20px;
        vertical-align: top;
        line-height: 1;
        margin:0 3px 0;
        font-size: 0;
        display: inline-block;
        width:20px;
        height: 20px;
        background-image: url(./icons/heart.png);
        background-size: 100% 100%;
    }
}
.App-general-btn:active{
    transform: scale(1.1);
    transition-property: transform;
}
.App-general-btn-green{
    background: url(./btn/green-r.png) no-repeat 100% 0;
    background-size: auto 100%;
    padding-right: 34px;
    margin-left: 1rem;
    position: relative;
}
.App-general-btn-green::before{
    display: inline-block;
    content: ' ';
    background: url(./btn/green-l.png) no-repeat 0 0;
    background-size: auto 100%;
    width: 34px;
    height:100%;
    position: absolute;
    left: -32px;
    top:0;
}
.App-general-btn-blue{
    background: url(./btn/blue-r.png) no-repeat 100% 0;
    background-size: auto 100%;
    padding-right: 34px;
    margin-left: 1rem;
    position: relative;
}
.App-general-btn-blue::before{
    display: inline-block;
    content: ' ';
    background: url(./btn/blue-l.png) no-repeat 0 0;
    background-size: auto 100%;
    width: 34px;
    height:100%;
    position: absolute;
    left: -32px;
    top:0;
}
.App-general-battle-btn{
    font-size: 13px;
    padding:7px 20px 3px;
    background-size:100% 30px;
}

.App-progress{
    background-color: #000;
    border-radius: 15px;
    height: 20px;
    border: 1px solid #5c493b;
    padding: 2px;
    overflow: hidden;
    position: relative;
}
.App-progress-blue{
    background: url(./assets/p2.png) no-repeat 10px 0;
    background-size: auto 100%;
    height: 100%;
    position: relative;
    border-radius: 15px;
}
.App-progress-blue::before{
    position: absolute;
    left:0;
    top:0;
    content:" ";
    display: block;
    height: 100%;
    width:10px;
    background: url(./assets/p1.png) no-repeat 0 0;
    background-size: auto 100%;
}
.App-progress-text {
    position: absolute;
    top: 0;
}